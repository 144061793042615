import React from "react";
import {
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import profile from "../../../images/profile-rayen.jpg";
import resume from "../../../resume/rayenbensaad_resume.pdf";

const socials = [
  {
    id: 1,
    icon: <FaFacebookF />,
    link: "https://www.facebook.com/rayen.bensaad/",
  },
  {
    id: 2,
    icon: <FaGithub />,
    link: "https://github.com/rayenbensaad",
  },
  {
    id: 3,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/bensaadrayen/",
  },
  {
    id: 4,
    icon: <FaInstagram />,
    link: "https://www.instagram.com/rayenbensaad/",
  },
  // {
  //   id: 5,
  //   icon: <FaBehance />,
  //   link: "#0",
  // },
  // {
  //   id: 6,
  //   icon: <FaDribbble />,
  //   link: "#0",
  // },
  // {
  //   id: 7,
  //   icon: <FaSkype />,
  //   link: "#0",
  // },
  // {
  //   id: 7,
  //   icon: <FaTwitter />,
  //   link: "#0",
  // },
];

const Sidebar = () => {
  return (
    <aside className="sticky top-0 bg-white md:mx-8 lg:mx-4 mb-8 p-6 shadow-md rounded-md -mt-40">
      <div className="w-24 h-24 rounded-md overflow-hidden mx-auto mb-5">
        <img src={profile} alt="shafiqhammad" className="w-full" />
      </div>
      <div className="text-center">
        <h1 className="text-xl text-gray-800 font-bold mb-1">Rayen Ben Saâd</h1>
        <p className="text-sm text-gray-400 mb-3">
          Software Engineer | Fullstack JS developer | Frontend developer -
          <a href="#0" className="text-blue-1000 pl-1">
            React.js, Next.js, Storybook
          </a>
        </p>
        <a
          href={resume}
          className="inline-block mb-3 rounded bg-blue-800 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-blue-1000"
          download="Rayen-Ben-Saad-Resume"
        >
          Download Resume
        </a>
        <ul className="flex flex-wrap justify-center">
          {socials.map((social, id) => (
            <SocialIcon social={social} key={id} />
          ))}
        </ul>
      </div>
      <div className="text-start pt-4">
        <h3 className="text-md mb-2 uppercase font-medium text-gray-800">
          About Me
        </h3>
        <p className="text-gray-400 text font-light leading-relaxed">
          As a passionate software engineer with a focus on full-stack
          development, I thrive on crafting innovative solutions that marry
          cutting-edge technology with user-centric design. With a strong
          foundation in both front-end and back-end development, I bring
          creativity and attention to detail to every project I undertake. My
          journey in the world of software engineering has been driven by a
          relentless curiosity to explore new technologies and solve complex
          problems. Through continuous learning and collaboration, I am
          committed to delivering high-quality, scalable solutions that exceed
          expectations and make a meaningful impact.
        </p>
      </div>
    </aside>
  );
};

export default Sidebar;

const SocialIcon = (props) => {
  const { icon, link } = props.social;
  return (
    <li className="m-2">
      <a
        href={link}
        target="_blank"
        className="w-8 h-8 bg-blue-100 rounded text-blue-1000 flex items-center justify-center hover:text-white hover:bg-blue-800"
      >
        {icon}
      </a>
    </li>
  );
};
