import React from "react";
import decidemos from "../../../images/portfolio/decidemos.png";
import landlock from "../../../images/portfolio/landlock.png";
import veamly from "../../../images/portfolio/veamly.png";
import hiver12 from "../../../images/portfolio/hiver12.png";

import PortfolioItem from "./PortfolioItem";

const portfolioData = [
  {
    id: 1,
    image: veamly,
    title: "Flowfinitee",
    link: "https://flowfinitee.com/",
    description:
      "A cross platform desktop applications a desktop application that enhances productivity by providing visibility into work patterns, minimizing distractions, and centralizing collaboration apps. Simplify time management, stay focused, and streamline your workflow effortlessly.",
  },
  {
    id: 2,
    image: landlock,
    title: "Landlock",
    link: "https://www.landlock.us/",
    description:
      "A web application that enables users to register and securely store their property information. The application incorporates user authentication, allowing individuals to create accounts, log in securely with persona verification and manage their property details with ease.",
  },
  {
    id: 3,
    image: decidemos,
    title: "Decidemos",
    link: "https://www.decidemos.fr/",
    description:
      "A deliberative web platform that allows its members to submit thematic proposals or candidacies according to electoral deadlines, to debate and vote according to a schedule.",
  },
  {
    id: 4,
    image: hiver12,
    title: "Incubation program",
    link: "https://www.incubator-forms.hive12.com/",
    description:
      "Hive12, a coworking space, provide a platform for an incubation program offering support to early-stage startups",
  },
];

const Portfolio = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {portfolioData.map((portfolio, id) => (
          <PortfolioItem portfolio={portfolio} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
