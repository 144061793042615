import React from "react";
import reactVSnext from "../../../images/blog/reactVSnext.jpg";
import storybook from "../../../images/blog/storybook.jpg";
import image3 from "../../../images/blog/blog-03.jpg";
import BlogItem from "./BlogItem";

const blogData = [
  {
    id: 1,
    image: reactVSnext,
    title: "🚀 Exploring the Powerhouse Duo: Next.js vs. React.js! 🤝",
    link:"https://www.linkedin.com/posts/bensaadrayen_reactjs-nextjs-webdevelopment-activity-7101549534073266176-S-LW?utm_source=share&utm_medium=member_desktop",
    description:
      "Let's unravel the differences between these two powerful technologies and understand when to wield each one in your projects.",
  },
  {
    id: 2,
    image: storybook,
    title: "🚀 Elevate Your React Workflow with Storybook!",
    link: "https://www.linkedin.com/posts/bensaadrayen_install-storybook-activity-7096978402284306432-9fHp?utm_source=share&utm_medium=member_desktop",
    description:
      "Storybook isn't just a collection of tales; it's a transformative tool that can revolutionize the way you develop and showcase React components.",
  },
  {
    id: 3,
    image: image3,
    title: "🎯 Micro-frontends: The Frontend Revolution 🔥",
    link: "https://www.linkedin.com/posts/bensaadrayen_micro-frontends-architecture-activity-7092621157425717248-XcrW?utm_source=share&utm_medium=member_desktop",
    description:
      "In today's dynamic tech landscape, building scalable hashtag#WebApplications demands flexibility, collaboration, and efficiency....",
  },
];

const Blog = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {blogData.map((blog, id) => (
          <BlogItem blog={blog} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Blog;
