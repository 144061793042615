import React from "react";
import { FaLaptopCode , FaSearchengin , FaCode, FaWordpress, FaReact } from "react-icons/fa";
// import { SiTailwindcss } from "react-icons/si";
import ServiceItem from "./ServiceItem";

const serviceData = [
  {
    id: 1,
    icon: <FaReact />,
    title: "Web Development",
    description:
      " Creating websites and web applications using various technologies such as HTML, CSS, JavaScript, and frameworks like React, Angular, or Vue.js.",
  },
  {
    id: 2,
    icon: <FaCode  />,
    title: "Backend Development:",
    description:
      "Developing server-side logic, databases, APIs, and other components necessary for web or mobile applications using technologies like Node.js (ExpressJS, NestJS),  PHP (Laravel).",
  },
  {
    id: 3,
    icon: <FaLaptopCode />,
    title: "Full-Stack Development",
    description:
      "Offering end-to-end development services, including both frontend and backend development, to deliver complete solutions.",
  },
  {
    id: 4,
    icon: <FaWordpress />,
    title: "CMS Development",
    description:
      "Creating content management systems (CMS) or customizing existing ones like WordPress to meet specific client needs.",
  },
  {
    id: 5,
    icon: <FaSearchengin  />,
    title: "SEO and Digital Marketing Integration",
    description:
      "Optimizing websites and applications for search engines (SEO) and integrating digital marketing tools and analytics for better visibility and performance.",
  },
];

const Service = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {serviceData.map((service, id) => (
          <ServiceItem service={service} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Service;
