import React from "react";
import Skills from "./Skills/Skills";

const About = () => {
  return (
    <section className="py-8">
      <div className="flex flex-wrap md:px-4">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className="text-2xl text-gray-800 font-bold mb-4">Who am I?</h3>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              Hi, I'm Rayen Ben Saad: full-stack web & app developer, amateur
              cook hobbyist, tinkerer, and general curious person. I can conjure
              a website or app from nothing into existence, fully formed. I
              specialize in manipulating the very fabric of the web, its raw
              materials of HTML, CSS, JavaScript, and Typescript. To launch
              these creations into the cosmos, I have studied the ancient tomes
              of sysadmin to create and control Linux servers, the golems of the
              cloud. Their true names are inscribed into the DNS so that
              everyone who speaks their names may summon them. And each and
              every one is sealed with an SSL certificate of authenticity.
            </p>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              A full-stack developer knows no bounds. And when they find one
              they cross it without hesitation, boldly going into the unknown to
              return richer for it.
            </p>
          </div>
          <Skills />
        </div>
      </div>
    </section>
  );
};

export default About;
